import {ILicenseExcemption,LicenseExcemptionUsageDescription} from '../../../interfaces/LicenseExcemption'

import {ReadConfigHelper} from '../../helper/ReadConfigHelper'
import Get from '../../helper/GetAPIHelper'

export async function GetExcemption(id:string | undefined) :Promise<ILicenseExcemption | undefined>
 {
    let res : ILicenseExcemption;
        var config =  await ReadConfigHelper();
        var apires = await Get(config.ApiUrl + config.getExcemption + "/" + id,config.AccessHeader)
        if(apires.IsOk)
        {
            res = await apires.Json;
            return res;
        }
        else
        {
            return undefined;
        }
        
        
 } 


    