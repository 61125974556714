import {ReadConfigHelper} from "./ReadConfigHelper"
     async function  GetToken()
    {
           var config = await ReadConfigHelper();
           let requestOptions = 
                {
                    method: 'Get',
                    headers: 
                    { 
                        'Content-Type': 'application/json',
                        'AccessHeader':config.AccessHeader ,
                        
                    },
                    // mode: "no-cors",
                };
              
                 var res = await  fetch(config.ApiUrl + config.getTokenUrl,requestOptions);
                var restext = await res.text();
               
                return restext;
    }
    export default GetToken;

   