import { useState, useMemo, useEffect } from 'react'
import { sortRows, paginateRows } from './TableHelper'
import {ITableState}  from '../../../interfaces/PageState';
import { PaginationComponent } from './Pagination';
import './Table.css';
interface TableProp 
{
    itemSelected: (id:string,tableState: ITableState) => void,
    columns : any | undefined,
    rows:any | undefined,
    tableState:ITableState | undefined,
    resetTable:number
     }

export const Table = (prop : TableProp) => 
{
  const [activePage, setActivePage] = useState(prop.tableState?.activePage)
  const [rowsPerPage, setRowsPerPage] = useState(prop.tableState?.pageSize)
  const [sort, setSort] = useState({ order: prop.tableState?.sortOrder, orderBy: prop.tableState?.sortColumn })
  
  


  const sortedRows = useMemo(() => sortRows(prop.rows, sort), [prop.rows, sort])
  var calculatedRows;

  if (sortedRows)
    calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage)

  const count = prop.rows.length
  const totalPages = Math.ceil(count / (rowsPerPage ?? 1))

  const handleSort = (accessor) => {
  
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    setActivePage(1);
  }
  useEffect(() => 
  {
    if(prop.resetTable > 0){
      setActivePage(1);
    }
  },[prop.resetTable])
  
  const handleClick = (id) => 
  {
       prop.itemSelected(id,{activePage: activePage,pageSize:rowsPerPage,sortColumn:sort.orderBy,sortOrder:sort.order});
  }

  const clearAll = () => {
    setSort({ order: 'asc', orderBy: 'id' })
    setActivePage(1)
  }


  return (
    <>
      
      <table className="table rtable table-striped">
        <thead>
          <tr className=''>
            <td colSpan={prop.columns.length} className='' >
              <span><strong>Antal träffar:</strong> {prop.rows.length}</span>
            <div className='float-right w-25'>
              
              <div className='w-25 float-right'>
              
              </div>
           </div>

            </td>
          </tr>
          <tr>
            {prop.columns.map((column) => {
              const sortIcon = () => {
                if (column.accessor === sort.orderBy) {
                  if (sort.order === 'asc') {
                    return 'sort-by-arrow-down'
                  }
                  return 'sort-by-arrow-up'
                } else {
                  return '️sort-by-arrow-both'
                }
              }
              return (
              
                <th key={column.accessor} className={`${column.cssClass} sort-by  ${sortIcon()}`}>
                  <a  onClick={() => handleSort(column.accessor)} className={`sort-by ${sortIcon()}`}>{column.label}</a>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {calculatedRows?.map((row) => {
            return (
              <tr key={row.id} >
                {prop.columns.map((column) => {
                  if(column.customFormat)
                  {
                    if(column.customFormat === "toLocaleString"){
                      //Antal decimaler ska såklart vara inställningsbart men for now så är det 5 eftersom det endast använts i de två första projekten som är gjorda med denna tabell.
                      return <td key={column.accessor}>{row[column.accessor].toLocaleString("sv-SE",{ minimumFractionDigits: 0, maximumFractionDigits: 5 })}</td>
                    }
                  }
                  else if (column.format) 
                  {
                    return <td key={column.accessor}>{column.format(row[column.accessor])}</td>
                  }
                  else if (column.showHtml){
                    return <td key={column.accessor}><div dangerouslySetInnerHTML={{__html: row[column.accessor]}}></div></td>
                    
                  }
                  else if(column?.linkname?.length > 0)
                  {
                    return <td key={column.accessor}><a className='selectlink' onClick={() => handleClick(row.id)}>{column.linkname}</a></td>
                  }
                  return <td key={column.accessor}>{row[column.accessor]}</td>
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
            <tr>
                <td colSpan={prop.columns.length}>
                  <div className='pagination-container'>
                {count > 0 ? (
                        <PaginationComponent 
                        cssClass="pagination-list"
                        activePage={activePage}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        totalPages={totalPages}
                        setActivePage={setActivePage}
                        setRowsPerPage = {setRowsPerPage}
                        
                        
                        />
                    ) : (
                        <p>Inga rader att visa</p>
                    )}
                    </div>
                </td>
               
            </tr>
        </tfoot>
      </table>
    </>
  ) 
}
export default Table;
