import Box from "../components/layout/box/Box";

export default function LicenseExcemptionHelpPage(){
    return(
        <>
		<Box>
        <div id="helpText" >
					<h1>
						Information om PTS sökfunktion för föreskrifter om undantag från tillståndsplikt för vissa radiosändare
					</h1>
					<br/>
                      <h2>Här kan du hitta information om vilka tillämpningar som är undantagna från tillståndsplikt.</h2>
					
						En sökning i undantagsföreskriften kan genomföras på två olika sätt. Antingen genom 
						att ange mellan vilka två frekvensband (fr.o.m - t.o.m) sökningen ska ske eller 
						genom att söka på de olika användningsområden som undantagsföreskriften har.
						<br />
						<br />
						Efter sökning visas en tabell med följande information:
						<ul>
							<li>
								<b>Fr.o.m MHz</b>
								<br />
								Den frekvens där frekvensbandet har sin startpunkt.
							</li>
						</ul>
						<ul>
							<li>
								<b>T.o.m MHz</b>
								<br />
								Den frekvens där frekvensbandet har sin slutpunkt.
							</li>
						</ul>
						<ul>
							<li>
								<b>Användning</b>
								<br />
								Beskriver vad undantaget används till genom att lista information om 
								nationell radioanvändning.
							</li>
						</ul>
						<ul>
							<li>
								<b>Referens</b>
								<br />
								Innehåller hänvisningar till harmoniserade beslut.
							</li>
						</ul>
						<ul>
							<li>
								<b>Anmärkning</b>
								<br />
								Beskriver övriga upplysningar om frekvensbanden.
							</li>
						</ul>
                        <ul>
							<li>
								<b>Villkor</b>
								<br />
								Tekniska villkor för undantaget.
							</li>
						</ul>
					<br/>
                    

					
                   <h2>1 kap. Tillämpningsområde m.m.</h2>
                   
<b>1 § </b>Dessa föreskrifter innehåller bestämmelser om undantag från tillståndsplikt enligt 3 kap. 4 § lagen (2022:482) om elektronisk kommunikation samt tekniska krav och övriga villkor för att radiosändare ska få användas utan individuellt tillstånd.
<br/><br/>
<b>2 § </b>Bestämmelser om undantag från tillståndsplikt för användning av vissa radiosändare anges i frekvensordning i 3 kap.<br/><br />Frekvenserna anges enligt följande intervall<br />
<p>
- i kilohertz (kHz), upp till och med 3 000 kHz<br/>
- i megahertz (MHz), över 3 MHz, upp till och med 3 000 MHz<br/>
- i gigahertz (GHz), över 3 GHz, upp till och med 3 000 GHz<br/><br />
                       I bilagan finns en förteckning över bestämmelser om undantag från tillståndsplikt, angivna efter tillämpningsområde
					   </p>                
                    
                    
                    
                    
                    
                    <h2>2 kap. Ord och uttryck</h2>
<b>1 § </b>I dessa föreskrifter avses med<br/><br/>
<em>amatörradiocertifikat:</em> kunskapsbevis utfärdat eller godkänt av Post- och telestyrelsen, som utvisar att godkänt kunskapsprov avlagts,<br/><br/>
<em>amatörradiosändare:</em> radiosändare som är avsedd att användas av personer som har amatörradiocertifikat, för sändning på frekvenser som är avsedda för amatörradiotrafik,<br/><br/>
<em>amatörradiotrafik:</em> icke yrkesmässig radiotrafik för övning, kommunikation och tekniska undersökningar, bedriven i personligt radiotekniskt intresse och utan vinstsyfte,<br/><br/>
<em>antennvinst:</em> förstärkning i förhållande till en referensantenn som antingen är isotropisk eller en dipol och som mäts i dBi eller dBd,<br/><br/>
<em>datanät:</em> flera kortdistansanordningar, inklusive nät-anslutningspunkten, så som nätkomponenter och de trådlösa anslutningarna mellan dessa,<br/><br/>
<em>fast installation:</em> anordning som är monterad, installerad och avsedd för permanent användning på en bestämd plats,<br/><br/>
<em>induktiv överföring:</em> överföring av information över mycket korta avstånd genom att utnyttja det magnetiska fältet i radiovågor,<br/><br/>
<em>intelligenta vägtransportsystem:</em> system och tjänster som bygger på informations- och kommunikationsteknik, bland annat bearbetning, kontroll, positionsbestämning, kommunikation och elektronik, och som tillämpas på ett vägtransportsystem,<br/><br/>
<em>larmöverföring:</em> användning av radiokommunikation för att indikera ett larmtillstånd vid en på avstånd belägen plats,<br/><br/>
<em>NMR:</em> (Nuclear Magnetic Resonance) kärnmagnetisk resonans. NMR-teknik är en kärnmagnetisk resonansexicitering och magnetisk fältstyrka för mätning av ett material/objekt som provas för att få information om materialet. Bildgenerering baserad på nukleär magnetresonans och magnetisk resonanstomografi omfattas inte av detta tillämpningsområde,<br/><br/>
<em>slutna NMR-sensorer:</em> anordningar där det undersökta materialet/föremålet placeras inuti NMR-anordningens inneslutning,<br/><br/>
                        <em>nätanslutningspunkt:</em> en nätanslutningspunkt i ett datanät är en fast mark-bunden kortdistansutrustning som fungerar som kopplingspunkt för andra kort-distansutrustningar i datanätet med serviceplattformar som är lokaliserade utanför datanätet,<br/><br/>
                        <em>radiopejling:</em> användning av radiokommunikation för att bestämma riktning till en radiosändare,<br/><br/>
                        <em>radiostyrning:</em> användning av radiokommunikation för sändning av signaler för att initiera, modifiera eller avsluta funktioner i utrustning på avstånd,<br/><br/>
                        <em>satellitterminal:</em> en station som är placerad på jordens yta eller i dess luftrum och som är avsedd för kommunikation med en eller flera satelliter, eller med andra stationer via satelliter,<br/><br/>
                        <em>sändningscykel:</em> den sammanlagda aktiva sändningstiden under en entimmes-period uttryckt i procent av denna period,<br/><br/>
                        <em>telemetri:</em> användning av radiokommunikation för att automatiskt indikera eller avläsa mätvärden på avstånd från mätinstrumentet samt signalering och datatransmission, avsedd för överföring av annan information än mätdata.<br/><br/>
                        <br/><br/>
<b>2 §</b> I dessa föreskrifter avses med<br/><br/>
<em>AES:</em> Aircraft Earth Station (satellitterminal på luftfartyg),<br/><br/>
                        <em>DAA:</em> Detect and Avoid,<br/><br/>
                        <em>dBd:</em> Decibel dipol (antennförstärkning i förhållande till en referens-dipolantenn. Denna antenn är definierad enligt: dBd = dBi -2,15),<br/><br/>
<em>dBi:</em> Decibel isotropisk (antennförstärkning av effekten i förhållande till en teoretisk antenn som strålar lika mycket i alla riktningar),<br/><br/>
<em>dBm:</em> Decibel milliwatt (effekt i förhållande till en milliwatt),<br/><br/>
                        <em>dBmikroA/m:</em> Decibel mikroampere/meter (fältstyrka relativt 1 mikroampere per meter),<br/><br/>
                        <em>dBW:</em> Decibel Watt (effekt i förhållande till 1 Watt),<br/><br/>
                        <em>DECT:</em> Digital Enhanced Cordless Telecommunications system (digital sladdlös telekommunikation),<br/><br/>
                        <em>EAS:</em> Electronic Article Surveillance (stöldskyddssystem för varor inom handeln),<br/><br/>
                        <em>EC-GSM IoT:</em> Extended Coverage-GSM-Internet of Things,<br/><br/>
                        <em>e.i.r.p.:</em> equivalent isotropically radiated power (ekvivalent isotropiskt utstrålad effekt),<br/><br/>
<em>e.r.p.:</em> effective radiated power (effektivt utstrålad effekt) relativt en halvvågsdipol,<br/><br/>
<em>ESV:</em> Earth Stations on-board Vessels (satellitterminal på fartyg),<br/><br/>
<em>GSM:</em> Global System for Mobile Telecommunications,<br/><br/>
                        <em>FDD:</em> Frequency Division Duplex,<br/><br/>
                        <em>LBT:</em> Listen Before Talk,<br/><br/>
                        <em>Ldc:</em> Low Duty Cycle,<br/><br/>
                        <em>LTE:</em> Long Term Evolution,<br/><br/>
                        <em>ms:</em> millisekund,<br/><br/>
                        <em>p.e.p.:</em> peak envelope power,<br/><br/>
<em>RFID (radiofrekvensidentifiering):</em> utrustning för RFID omfattar tagg-/ interrogatorsbaserade radiokommunikationssystem, bestående av radioenheter (taggar) fästa på objekt eller levande varelser och av sändar-/mottagarenheter (RFID-läsare) som aktiverar taggarna och tar emot data från dessa. Typiska användningsområden är att spåra och identifiera föremål, t.ex. för elektronisk artikelövervakning (EAS), och samla in och överföra data som avser de artiklar på vilka taggarna är fästa, som kan vara antingen batterilösa, batteriassisterade eller batteridrivna. Svaren från en tagg valideras av en RFID-läsare,<br/><br/>
<em>r.m.s.:</em> root mean square (kvadratiskt medelvärde),<br/><br/>
                        <em>TPC:</em> Transmitter Power Control,<br/><br/>
                        <em>t.r.p:</em> total radiated power (totalt utstrålad effekt),<br/><br/>
                        <em>UMTS:</em> Universal Mobile Telecommunications System,<br/><br/>
                        <em>UWB:</em> Ultra Wideband (kortdistansradiosändning som utnyttjar radiofrekvent energi spridd över ett spektrum bredare än 50 MHz),<br/><br/>
<em>VHF:</em> Very High Frequency (frekvensområdet 30–300 MHz),<br/><br/>
                        <em>WiMAX:</em> Worldwide Interoperability for Microwave Access.<br/><br/>

				</div>
				</Box>
        </>
    )
}

