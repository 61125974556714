import { format } from 'path';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {IGenericFrqSearchFilter} from '../../../interfaces/IGenericFrqSearchFilter'
import {ValueDescription} from '../../../interfaces/ValueDescription'
import './Filter.css'

import React, { SyntheticEvent,useRef } from 'react';
  interface FilterProp 
  {
      onFilterChange: (filter: IGenericFrqSearchFilter) => void,
      descriptions : ValueDescription[] | undefined,
      filter:IGenericFrqSearchFilter | undefined
  }

function GenericFrqSearchFilter(props : FilterProp)
{
    const {onFilterChange,descriptions} = props;
    
    const [filter, setFilter] = useState(props.filter);
    const submitBtnRef = useRef<HTMLButtonElement>(null);
    const handleSearchClick = () =>  {
      if(filter){
        onFilterChange(filter);
      }
    }
    const handleChange = (event: any) => 
    {
            const { type, name, value, checked } = event.target;
            let updatedValue = type === 'checkbox' ? checked : value;
        
            if (type === 'number') 
            {
              updatedValue = Number(updatedValue);
            }
            const change = 
            {
              [name]: updatedValue,
            };
        
            let updatedFilter: any;
             setFilter((p) => 
             {
                 updatedFilter = { ...p, ...change };
                 return updatedFilter;
             });
      };
      const handleSubmit = (event: SyntheticEvent) => 
      {
            event.preventDefault();
            const target = event.target as typeof event.target & {
                fromMHz: string | undefined ;
                toMHz: string | undefined;
                usageDescriptionId: number | undefined;

              };
            
              let res  = { fromMHz : target.fromMHz, toMHz : target.toMHz,usageDescriptionId: target.usageDescriptionId }
             
              onFilterChange(res);
      };
      const onKeydown = (event) => 
      {
        if(event.key === 'Enter'){
          submitBtnRef?.current?.click();
        }
      }
    return (
      <div className='pl-2 filter-maincontainer' >
        <form onSubmit={handleSubmit} onKeyDown={onKeydown}>

            <div className='pt-3 pb-4 ml-2 filter-innercontainer'> 
              <Container className='filter-container' >
                <Row>
                    <Col ><div ><label htmlFor="name" className='float-left'>MHz från</label></div></Col>
                    <Col><label htmlFor="name">MHz till</label></Col>
                    <Col xs={6}><label htmlFor="name">Användningsområde</label></Col>
                    <Col></Col>
              </Row>
              <Row>
                <Col><input type="text"  className="form-control"  name="fromMHz"  value={filter?.fromMHz} onChange={handleChange}  /></Col>
                <Col> <input type="text" className="form-control"  name="toMHz"  value ={filter?.toMHz} onChange={handleChange} /></Col>
                <Col xs={6}><select name="usageDescriptionId" className="form-select form-control"   value={filter?.usageDescriptionId} onChange={handleChange}  >
                <option value="0" >-- Välj användningsområde --</option>
                  { descriptions?.map((desc) => (
                    <option key={desc.id} value={desc.id}>{desc.description}</option>
                  ))}
                 </select>
                 </Col>
                 <Col><button type="button"  className="btn btn-default" onClick={handleSearchClick} ref={submitBtnRef}>Sök</button></Col>
              </Row>
              </Container>
         </div> 
       
       
    </form>
    </div>
    );
}
export default GenericFrqSearchFilter;