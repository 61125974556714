import "./PtsLink.css"

export enum LinkType{
    Document,
    External,
    Empty
}

export interface ILinkProps
{
    url: string;
    title:string;
    linktext:string;
    type: LinkType;
}
const documentlink = (props : ILinkProps) => 
{
    var svg = getLinkSvg(props.type);
   return (

    <>
    
    <a href={props.url} className="link icon ptslink" title={props.title} target="_blank">

    {svg}
            <span > {props.linktext}</span>
            </a>
        
    </>
            
    )
}
export const getLinkSvg = (type: LinkType ) => {
    var svg = <></>
    if(type == LinkType.Document){
        svg = svgDocument;
   }else if(type == LinkType.External){
        svg = svgExternalLink;
   }
   return svg;
}
//Gem
//export const svgDocument = (<svg width="15" height="17" viewBox="0 0 15 17" fill="none"><path d="M13.2861 10.6088L9.32518 14.5697C7.41809 16.4768 4.33741 16.4768 2.43032 14.5697C0.523227 12.6626 0.523227 9.58191 2.43032 7.67482L8.10269 1.95355C9.37408 0.682152 11.4279 0.682152 12.6993 1.95355C13.9707 3.22494 13.9707 5.27873 12.6993 6.55012L7.32029 11.9291C6.6846 12.5648 5.6577 12.5648 5.02201 11.9291C4.38631 11.2934 4.38631 10.2665 5.02201 9.63081L8.68949 5.96333" stroke="#6E3282" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>);
const svgDocument = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="14" height="14" fill = "#6e3282" className="icon baseline"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"/></svg>)

export const svgExternalLink = (<svg width="15" height="15" viewBox="0 0 16 16" fill="none"><path d="M12.6667 10.1391V15.0002H1V3.3335H5.86111" stroke="#6E3282" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" className="icon baseline"></path><path d="M15 6.5V1H9.5" stroke="#6E3282" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15 1L7.5 8.5" stroke="#6E3282" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>);


export default documentlink;
