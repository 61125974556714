//keys for session
export const KeyForSessionForAllLicenceExcemptions="AllLicenseExcemptions";
export const KeyForSessionForAllUsageLicenceExcemptionDescriptions="AllLicenceExcemptionUsageDescriptions";
export const KeyForLicenceExcemtionSearchPageState="LicenseExcemptionPageState";

export const KeyForSessionForAllFrqPlans = "AllFrq";
export const KeyForSessionForAllFrqPlansUsageDescriptions = "AllFrqUsageDescriptions";


//navigate
export const NavStartPage="/license-excemption/start";
export const NavDetailPage="/license-excemption/detail/:id";
export const HelpPage ="/help"
export const PdfLinkExemptions ="https://pts.se/contentassets/475c0285823d4966b321ca4ef4a6b4de/ptsfs-2022-19-undantag-fran-tillstandsplikt-for-anvandning-av-vissa-radiosandare.pdf"
export const UrlToFrqPdf = "https://pts.se/contentassets/dd03b7d4f64543dabddb305f96a54ea2/pts_allmanna-rad-om-den-svenska-frekvensplanen-ptsfs2019-1.pdf"
export const SwedenDefaultCountry="Sverige"
