    export function  ReadConfigHelper()  
    {
      return fetch('/config.json').
      then((response) => response.json())
      .then((responseData) => {
        return responseData;
      });
    }
    
    export async function ReadConfigHelperAsync()
    {
      var res= await fetch('/config.json');
      return res.json();
    } 
    