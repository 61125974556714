import * as excemptionLang from '../../licenseexcemption/LicenseExcemptionLang'
import { ReadConfigHelper } from '../../helper/ReadConfigHelper';
import Get from '../../helper/GetAPIHelper';
import { ValueDescription } from '../../../interfaces/ValueDescription';

 export async function  GetLicenseExcemptionUsageDescriptions(setLoadError) : Promise<Array<ValueDescription>|undefined>{
    var jsonString : string | null = sessionStorage.getItem(excemptionLang.KeyForSessionForAllUsageLicenceExcemptionDescriptions);
   
    if(jsonString == null)
    {
        let config = await ReadConfigHelper()
      let response = await Get(config.ApiUrl + config.getExcemptionUsageDescriptions,config.AccessHeader)
      if(response.IsOk)
      {
        const jsonData = await response.Json;
        sessionStorage.setItem(excemptionLang.KeyForSessionForAllUsageLicenceExcemptionDescriptions,JSON.stringify(jsonData));
        return jsonData;
      }
      else
      {
        setLoadError("Fel då data skulle hämtas från servern");
        return [];
      }
      
    }
    else
    {
        var descriptions : Array<ValueDescription> = JSON.parse(jsonString ?? "");
        return descriptions;
    }
 } 

    