import { IPageState } from '../../../interfaces/PageState';
import * as excemptionLang from '../../licenseexcemption/LicenseExcemptionLang'
export  function GetPageState() :IPageState
 {
    var jsonString : string | null =  sessionStorage.getItem(excemptionLang.KeyForLicenceExcemtionSearchPageState);
   
    if(jsonString != null)
    {
        return JSON.parse(jsonString); 
    }
    else
    {
      return GetDefaultPageState();
    }
    
 } 
 export function GetDefaultPageState()
 {
   return {tableState:{activePage:1,pageSize:25,sortColumn:'fromMHz',sortOrder:'asc'}, filter: {fromMHz:"",toMHz:"",usageDescriptionId:0}};
 };
 export function SavePageStateToStorage( state: IPageState )
 {
    sessionStorage.setItem(excemptionLang.KeyForLicenceExcemtionSearchPageState,JSON.stringify(state || ""));
 };
    