import {GetExcemption} from '../components/data/licenseExcemption/GetLicenseExcemption';
import LicenseExcemptionsDetailComponent from '../components/licenseexcemption/LicenseExcemptionDetailComponent';
import { ILicenseExcemption } from '../interfaces/LicenseExcemption';
import {useState,useEffect } from 'react'; 
import { useParams } from 'react-router-dom'
import * as excemptionLang from "../components/licenseexcemption/LicenseExcemptionLang";
import React from 'react'
import Box from '../components/layout/box/Box';


function LicenseExcemptionsDetailPage()
{
   
    let {id} = useParams();
    const [Excemption,SetExcemption] = useState<ILicenseExcemption | undefined>({id:Number(id),fromMhz:0,toMhz:0,condition:"",reference:"",usageDescriptionLicenseExcemptionId:0,usageDescriptionLicenseExcemptionUsageDescription:""}); 
    useEffect(() => 
    {
        const GetDetail = async ()  => 
        {
            SetExcemption(await GetExcemption(id))
        };
        GetDetail();
      },[])
    
         
    return(
        <>
        <Box>
        <div className='mt-4'>
            
                <LicenseExcemptionsDetailComponent  excemption={Excemption}   />
           
        </div>
        <a href={excemptionLang.NavStartPage} className='btn btn-default'>Tillbaka</a><br/>
        </Box>
            </>
    )
}
export default LicenseExcemptionsDetailPage;
