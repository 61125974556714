import { useState,useEffect  } from "react";
import GetToken from "./GetTokenHelper";

export interface IApiResult
{
    IsOk : Boolean,
    Json : any | undefined,
    StatusCode : Number,
    StatusMessage : string | undefined
}

 async function  Get(url,accessHeader) : Promise<IApiResult>
{
    
    const   GetFromApi = async (url,token,accessHeader) : Promise<IApiResult> =>
    {
        
        const requestOptions = 
        {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
        "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
            "Access-Token":token,
            "AccessHeader":accessHeader
           
          },
          };

          try{
            var getRes = await fetch(url, requestOptions);
           
            return {IsOk : getRes.ok, StatusCode : getRes.status,StatusMessage : getRes.statusText,Json :  await getRes.json()};

          }catch(error){
            return {IsOk : false, StatusCode : 500,StatusMessage : "UnknownError",Json : "xxx"};
          }
            
        }

        var token =  await GetToken();
        
        var res= await GetFromApi(url,token,accessHeader);
        return res;
    }
export default Get

