// import logo from './logo.svg';
import './App.css';
import './components/common/table/Pagination.css';
import React, { useEffect, useState }  from 'react';
import {Header,Footer} from 'ui-components-react-package-45ds546dksw34';
//import  Header from './components/layout/header/Header'
//import  Footer from './components/layout/footer/Footer'


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as excemptionLang from "./components/licenseexcemption/LicenseExcemptionLang";
import LicenseExcemptionsDetailPage from './pages/LicenseExcemptionDetailPage';
import LicenseExcemptionsPage from './pages/LicenseExcemptionsPage';

import {
  Container 
} from "react-bootstrap";
import { ILicenseExcemption } from './interfaces/LicenseExcemption';

import LicenseExcemptionHelpPage from './pages/LicenseExcemptionDetailHelpPage';
//import Header from './components/layout/header/Header';





function App() 
{

  return (
   
      <div className="d-flex flex-column h-100" style={{ minHeight: '100vh' }}>
<Router>
  
  <Header/>
<Container  className="main-container" fluid="sm" style={{ marginBottom: '36px' }}>


    <Routes>
    
    <Route path={excemptionLang.NavStartPage}  element={< LicenseExcemptionsPage/> } />
    <Route path={excemptionLang.NavDetailPage}  element={<LicenseExcemptionsDetailPage/> } />
    <Route path={excemptionLang.HelpPage}  element={<LicenseExcemptionHelpPage/> } />
    <Route path="/" element={<LicenseExcemptionsPage/>} />
    
  </Routes>
      
  
   
  </Container>
  <Footer/>
  </Router>

  
      
    </div>

  );
}

export default App;


/*<Route path="vhf" excat element={<Step1Vhf/> } 
/>
<Route path="/vhf/preview" excat element={<Preview/> } />*/

