import Spinner from 'react-bootstrap/Spinner';

  

const Loader=(startLoader)=>
{


return(
    <>
    <style>{`.spinner-div {text-align:center;margin-top:10px}`}</style>    
    <div className='spinner-div'>


    <Spinner animation="border" role="status"  >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  
          
          </div>
          
    </>

        )
}
        export default Loader;

        //https://www.npmjs.com/package/react-spinners