import "./Box.css"


const Box = (props) => {
const css = "box-background box " + props?.propsCss;
return (

<>
<div className={css}>
    {props.children}
</div>
	
</>
		
)
}
export default  Box;