import { propTypes } from "react-bootstrap/esm/Image";
import { ILicenseExcemption } from "../../interfaces/LicenseExcemption";
import React, { Fragment } from 'react'
import * as excemptionLang from "../../components/licenseexcemption/LicenseExcemptionLang";
import PtsLink, { LinkType } from "../layout/ptslink/PtsLink";
interface IExcemptionProp {
    excemption: ILicenseExcemption | undefined
 }

function LicenseExcemptionsComponent(props : IExcemptionProp)
{
    const interval = (props?.excemption?.fromMhz) + ' - ' + (props?.excemption?.toMhz) + " MHz" ;
    
    return (
    <>
    <div className="col-sm-12">
                <h1>Undantag från tillståndsplikt för vissa radiosändare</h1>
                        <div id="pageTitle">
                           
                           <h2 >
                                Här visas de tekniska villkoren för <label id="lblTitle">	{props?.excemption?.usageDescriptionLicenseExcemptionUsageDescription?.toLowerCase() } ({interval})</label>
                            </h2>
                            <h3>
                                Referens
                            </h3>
                            <p>{props?.excemption?.reference}</p>
                           
                            <br/><br/>
                    
                        </div>
                        
                        <div id="pageText" dangerouslySetInnerHTML={{__html: props?.excemption?.condition ?? ""}}></div>
                            <br/>
                        <div>  <PtsLink url={excemptionLang.HelpPage} 
                                title="Förkortningar och definitioner" 
                                linktext = "Förkortningar och definitioner"
                                type={LinkType.Empty}    />                
                      </div>
                        
             </div>
 
        <br/>
        
    </>);
}
export default LicenseExcemptionsComponent;