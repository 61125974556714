import {GetExcemptions} from '../components/data/licenseExcemption/GetLicenseExcemptions';
import {GetPageState,GetDefaultPageState,SavePageStateToStorage} from '../components/data/licenseExcemption/ExcemptionPageState';
import {GetLicenseExcemptionUsageDescriptions} from '../components/data/licenseExcemption/GetLicenseExcemptionsDescriptions'
import GenericFrqSearchFilter  from '../components/common/filters/GenericFrqSearchFilter';
import { ILicenseExcemption } from '../interfaces/LicenseExcemption';
import {useEffect, useState } from 'react'; 
import Table from '../components/common/table/Table';
import {  useNavigate } from "react-router-dom";
import * as excemptionLang from "../components/licenseexcemption/LicenseExcemptionLang";
import Loader from '../components/helper/Loader';
import { IPageState,ITableState} from '../interfaces/PageState';
import { ValueDescription } from '../interfaces/ValueDescription';
import { IGenericFrqSearchFilter } from '../interfaces/IGenericFrqSearchFilter';
import Box from '../components/layout/box/Box';

import PtsLink, { LinkType } from '../components/layout/ptslink/PtsLink';


 function LicenseExcemptionsPage()
 {
    
    const [unfilteradExcemptions,setunfilteradExcemptions] = useState<ILicenseExcemption[] | undefined>() ;
    const [excemptions,setExcemptions] = useState<ILicenseExcemption[] | undefined>() ;
    const [descriptions,setDescriptions] = useState<ValueDescription[] | undefined>() ;
    const [pageState,setPageState] = useState<IPageState>() ;
    const [isLoading,setLoading] = useState(true) ;
    const [loadError,setLoadError] = useState("");
    const [resetTable,setResetTable] = useState(0);
    const navigate = useNavigate();
   
    
useEffect(() => 
{
    const GetData = async ()  => 
    {
       
        const awaitExcemptions = await GetExcemptions(setLoadError);
        const awaitDescriptions = await GetLicenseExcemptionUsageDescriptions(setLoadError);
        const awaitPageState = await GetPageState();
       
        Promise.all([awaitExcemptions,awaitDescriptions,awaitPageState]).then((responses) => {
            
            setunfilteradExcemptions(responses[0]);
            
            setDescriptions(responses[1]);
            setPageState(responses[2]);
            
            //if((awaitPageState?.filter?.fromMhz ?? 0) > 0 || (awaitPageState?.filter?.toMhz ?? 0) > 0 || (awaitPageState?.filter?.usageDescriptionPlanId ?? 0) > 0){
                setExcemptions(getFilteredExcemptions(awaitPageState.filter,awaitExcemptions));
            //}

            setLoading(false);
          
        });
        
    };
    GetData();
    

  },[])

    const filterChanged = (filter : IGenericFrqSearchFilter) => 
    {
        
        let newState : IPageState;
        if(pageState)
        {
            newState =  pageState;
            newState.filter = filter;
            if(newState.tableState)
                newState.tableState.activePage = 1;
            
            SavePageStateToStorage(newState);
            setPageState(newState);
           
            setResetTable(resetTable+1);
        }
      
        setExcemptions(getFilteredExcemptions(filter,unfilteradExcemptions));
    }
   
    const getFilteredExcemptions = (filter : IGenericFrqSearchFilter, dataToFilter : ILicenseExcemption[] | undefined ) => 
    {
        let from :number = 0;
        let to :number = 0
        
        try{
            from = Number(filter.toMHz?.replace(",","."));
        }
        catch{}
        
        try{
            to = Number(filter.fromMHz?.replace(",","."));
        }
        catch{}
        
        return dataToFilter!.filter(excemp => {
            return(
            (excemp !== undefined) &&
            (!filter.fromMHz ||  ((excemp.fromMhz || 0) >= from) || ((excemp.toMhz || 0) >= to) ) &&
            (!filter.toMHz || ((excemp.toMhz || 0) <= to) || ((excemp.fromMhz || 0) <= from) ) &&
            (filter.usageDescriptionId == 0  || filter.usageDescriptionId==excemp.usageDescriptionLicenseExcemptionId )
        )})
    }

    
    const onSelect = (id :string,tableState : ITableState ) => {
        let newState : IPageState;
        if(pageState)
        {
            newState =  pageState;
            newState.tableState = tableState;
            SavePageStateToStorage(newState);
        }
        
        navigate(excemptionLang.NavDetailPage.replace(':id',id));
       
    };
    const columns = [
       // { accessor: 'id', label: 'Id' },
        { accessor: 'fromMhz', label: 'MHz från',customFormat:"toLocaleString", cssClass:"w-5" },
        { accessor: 'toMhz', label: 'MHz till',customFormat:"toLocaleString" , cssClass:"w-5"},
        { accessor: 'usageDescriptionLicenseExcemptionUsageDescription', label: 'Användningsområde', cssClass:"w-60" },
        { accessor: 'id', linkname: 'Visa detaljer', label:'', cssClass:'w-30 text-right'}
      ]
    
      if (isLoading)
      {
        return(
          <div><Loader></Loader></div>
        )
      }
    return(
       <>
            <Box>
                <h1>Undantag från tillståndsplikt för vissa radiosändare</h1>

                 <div>Välkommen till Post och telestyrelsens (PTS) e-tjänst. Här kan du söka på frekvensband och användningsområde för att få information om den huvudsakliga användningen.</div>
                <br/>

                <p>Hela frekvensplanen och PTS föreskrifter om undantag från tillståndsplikt kan även laddas ner i PDF-format.</p>
                <PtsLink url={excemptionLang.PdfLinkExemptions} 
                    title="Pdf-version av undantagsföreskriften för utskrift" 
                    linktext = "Föreskrifter om undantag (PDF)"
                    type={LinkType.Document}  /><br/>
                <PtsLink url={excemptionLang.UrlToFrqPdf} 
                    title="Pdf-version av hela frekvensplanen för utskrift" 
                    linktext = "Hela frekvensplanen (PDF)"
                    type={LinkType.Document}  />
                    <br/><br/>
                <PtsLink url={excemptionLang.HelpPage} 
                    title="Förkortningar och definitioner" 
                    linktext = "Förkortningar och definitioner"
                    type={LinkType.Empty}  />
               
               
            </Box>
            
            <div className='mt-4'>
            <Box>
                    <div>
                        <GenericFrqSearchFilter onFilterChange={filterChanged} descriptions={descriptions} filter = {pageState?.filter}    />
                    </div>
                   
                    <div>
                        <Table rows={excemptions} columns={columns} itemSelected={onSelect} tableState={pageState?.tableState} resetTable={resetTable} ></Table>
                    </div>
               
            </Box>
            </div>
            </>  
           
    )
}
export default LicenseExcemptionsPage;
