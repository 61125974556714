
import Pagination from 'react-bootstrap/Pagination';
import './Pagination.css'
export const PaginationComponent = ({ activePage, count, rowsPerPage, totalPages, setActivePage,setRowsPerPage,cssClass }) => {

    const maxNumberOfVisiblePages = 8;//8 är max antal sidor som ska visas ta bort hårdkodning sen
    const visiblePages = getVisiblePageNumbers(count,rowsPerPage,activePage,maxNumberOfVisiblePages); 
    
    const handlePageSizeChange= (event) => 
  {
    if(event.target.value == "0")
    {
     
        setRowsPerPage(count);
    }
    else
    {
      setRowsPerPage(event.target.value);
    }
    setActivePage(1);
    
  }

    function getVisiblePageNumbers(totalItems, itemsPerPage, currentPage, visiblePages) {
      const totalPages = Math.ceil(totalItems / itemsPerPage);
      const halfVisible = Math.floor(visiblePages / 2);
    
      let startPage = currentPage - halfVisible;
      if (startPage < 1) {
        startPage = 1;
      }
    
      let endPage = startPage + visiblePages - 1;
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = endPage - visiblePages + 1;
        if (startPage < 1) {
          startPage = 1;
        }
      }
    
      const visiblePagesArray = [];
      for (let i = startPage; i <= endPage; i++) {
        visiblePagesArray.push(i);
      }
    
      return visiblePagesArray;
    }

    return (
      <>
      <div className='pagination-div'>
      <span className="float-left">Sida {activePage} av {totalPages}</span>
        <Pagination className={cssClass}>
      <Pagination.First onClick={() => setActivePage(1)}/>
      <Pagination.Prev onClick={() => setActivePage(activePage - 1)}/>
      {visiblePages.map(page => (  
         
            <Pagination.Item key={page} active={page === activePage} onClick={() => setActivePage(page)} >{page}</Pagination.Item>
           
        ))}  
     
      
      
      <Pagination.Next onClick={() => setActivePage(activePage + 1)} />
      <Pagination.Last onClick={() => setActivePage(totalPages)}/>
    </Pagination>
    <div className="page-size-div">
      <label for ="pagesize">Sidstorlek: </label>
                <select id="pagesize" onChange={handlePageSizeChange} value={rowsPerPage} className="form-select form-control ">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="100">100</option>
                            <option value={count}>Alla</option>
                        </select>
      </div>
   </div>
      </>
    )
  }
  