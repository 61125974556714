import {ILicenseExcemption,LicenseExcemptionUsageDescription} from '../../../interfaces/LicenseExcemption'
import * as excemptionLang from '../../licenseexcemption/LicenseExcemptionLang'

import {ReadConfigHelper} from '../../helper/ReadConfigHelper'
import Get from '../../helper/GetAPIHelper'

export async function GetExcemptions(setLoadError : any) :Promise<Array<ILicenseExcemption> | undefined>
 {
    var jsonString : string | null = sessionStorage.getItem(excemptionLang.KeyForSessionForAllLicenceExcemptions);
    let res : Array<ILicenseExcemption> ;
    if(jsonString==null)
    {
      let config = await ReadConfigHelper()
      let response = await Get(config.ApiUrl + config.getAllExcemptions,config.AccessHeader)
      if(response.IsOk){
        const jsonData = await response.Json;
        sessionStorage.setItem(excemptionLang.KeyForSessionForAllLicenceExcemptions,JSON.stringify(jsonData));
        return jsonData;
      }else{
        setLoadError("Fel då data skulle hämtas från server");
        return [];
      }
      
    }
    else
    {
        res = await JSON.parse(jsonString ?? "")
        return res; 
    }
 } 


// export const FakeDescriptions = _descriptions as Array<LicenseExcemptionUsageDescription>;
    